import * as React from 'react';
import loadable from '@loadable/component';

import Layout from '../components/Layout';
import { LangProvider } from '../context/lang/langContext';

const BannerPrincipal = loadable(() => import('../components/BannerPrincipal'));
const ComoTrabajamos = loadable(() => import('../components/ComoTrabajamos'));
const Footer = loadable(() => import('../components/Footer'));
const Header = loadable(() => import('../components/Header'));
const QuienesSomos = loadable(() => import('../components/QuienesSomos'));
const Tecnologia = loadable(() => import('../components/Tecnologia'));

const IndexPage = () => (
    <LangProvider>
        <Layout>
            <Header />
            <BannerPrincipal />
            <Tecnologia />
            <ComoTrabajamos />
            <QuienesSomos />
            <Footer />
        </Layout>
    </LangProvider>
);

export default IndexPage;
